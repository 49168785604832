<template>
  <v-container>
    <v-row v-if="loading" align="center" justify="center">
      <v-progress-circular indeterminate color="primary" />
    </v-row>
    <v-img v-else :src="newsData.image" class="white--text d-flex flex-column align-end">
      <div class="mb-3 ml-3">
        <h1 style="text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5)" class="text-h3">{{ newsData.title }}</h1>
      </div>
    </v-img>
    <div v-html="newsData.content" />
  </v-container>
</template>

<script>
import firebase from '@/plugins/firebase'

export default {
  name: 'NewsView',

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data () {
    return ({
      newsData: {},
      loading: false
    })
  },

  methods: {
    getNews () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(`news/${this.id}`).get()
          .then(doc => { resolve(doc.data()) })
          .catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    this.$data.loading = true
    try { this.$data.newsData = await this.getNews() } catch (e) {
      this.$store.commit('toast/setError', 'Gagal mengambil berita.')
    }
    this.$data.loading = false
  }
}
</script>
